import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NotAvailableComponent } from './not-available/not-available.component';
import { environment } from '../environments/environment';
import { Constant as constants } from './shared/constant';


const routes: Routes = [
  {
    path: '',
    loadChildren: async () => {
      const url = environment.locationUrl;
      let response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Connection': 'keep-alive',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Sec-Fetch-Mode': 'cors',
          'Sec-Fetch-Site': 'cross-site',
        }
      });
      if (response.ok) {
        localStorage.setItem('redirectToEnphase', 'false');
        let json = await response.json();

        let country = json.countryCode

        let navigationURL = country;
        if(navigationURL === 'GB' || navigationURL === 'IN' || navigationURL === 'NL' || navigationURL === 'NLD') {
          return import('./uk/uk.module').then(m => m.UKModule);
        } else if(navigationURL === 'BR') {
          return import ('./brazil/brazil.module').then(m => m.BrazilModule);
        } else {
          console.log('Setting LocalStorage');
          
          localStorage.setItem('redirectToEnphase', 'true');
          const shouldRedirect = localStorage.getItem('redirectToEnphase');
          console.log('shouldRedirect = ', shouldRedirect);
          
        }
      } else {
        alert("Not Able to Load Location Info");
      }
      return import('./uk/uk.module').then(m => m.UKModule);
    },
  },
  {
    path: 'unavailable',
    component: NotAvailableComponent,
  },
  {
    path: 'GB',
    loadChildren: () => import('./uk/uk.module').then(m => m.UKModule)
  },
  {
    path:"IN",
    loadChildren: () => import ('./in/in.module').then(m => m.INModule)
  },
  {
    path:"BR",
    loadChildren: () => import ('./brazil/brazil.module').then(m => m.BrazilModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
