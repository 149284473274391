import { Injectable } from '@angular/core';

@Injectable()
export class Constant {
  public ukFields = {
    postCode: true,
  };
  public error = {
    POSTCODE_BLANK_ERROR: 'Postcode should not be blank *',
    PINCODE_BLANK_ERROR: 'Pincode should not be blank *',
    PINCODE_EXCEED_ERROR: 'Pincode should not exceed more than 6 digits *',
    PINCODE_VALID_ERROR: 'Please enter valid pincode *',
    SOMETHING_WENT_WRONG: 'Something Went Wrong',
    INTERNAL_ERROR: 'Internal Server Error',
    FETCH_DATA_ERROR: 'Error in fetching data',
    SPACE_ERROR: 'Please enter postcode without any spaces & try again.',
    PINCODE_REQUIRED: 'Pincode is required',
    BILL_REQUIRED: 'Bill is required',
    POSTCODE_REQUIRED: 'Postcode is required',
    TARIFF_TYPE_REQUIRED: 'Tariff Type is required',
    BILLING_CYCLE_REQUIRED: 'Billing Cycle is required',
    NAME_REQUIRED: 'Name is required',
    PHONE_NUMBER_REQUIRED: ' Phone Number is required',
    PHONE_NUMBER_INVALID: 'Please Enter a valid Phone Number',
    PHONE_NUMBER_LENGTH_INVALID: 'Phone Number must be 10 digits long',
    EMAIL_REQUIRED: 'Email is required',
    EMAIL_INVALID: 'Please Enter a valid Email',
    CAPTCHA_REQUIRED: 'Captcha is required',
    CAPTCHA_MISMATCH: ' Captcha did not match, Please try again',
  };
  public labels = {
    UK: 'GB',
    IN: 'IN',
    NL: 'NL',
    BR: 'BR',
    PINCODE: 'Pincode',
    POSTCODE: 'Postcode',
    go_solar: 'Go Solar today.',
    find_right_system_size: 'Find the right system size for your home',
    get_started: 'Let’s get started',
    heading: 'Help us understand your requirements better.',
    next: 'Next',
    system_estimator: 'System Estimator',
    enphase_support: 'Get Enphase Support',
    email: 'contact@enphase.com',
    footer_text:
      'Prefer to connect directly with an Enphase solar expert ? Click',
    recommend_text: 'For your energy needs, we recommend',
    here: 'here',
    solar_panels: ' Solar Panels',
    roof_required: ' sq. ft of roof area required',
    enter_ebill: 'Enter your average electricity bill',
    enter_ebill_in: 'Enter your average monthly electricity bill',
    tariff_type: 'Tariff Type',
    standard_tariff: 'Standard Tariff',
    economy: 'Economy 7 Tariff',
    billing_cycle: 'Billing Cycle',
    monthly: 'Monthly',
    quarterly: 'Quarterly',
    euro: '£ per month',
    rupee: '₹ per month',
    estimated: 'Estimated energy consumption',
    units: ' units/mo',
    uk_avg_rates: 'United Kingdom average rates*',
    Standard: 'Standard',
    Economy: 'Economy',
    tariff: ' Tariff Charges',
    standing_charge: 'Standing charge/day',
    variable_charge: 'Variable charge/kWh',
    peek_charge: 'Peak time charge/kWh',
    off_peek_charge: 'Off-Peak time charge/kWh',
    units_per_month: ' kWh',
    avg_slab_rate: ' average slab wise rates*',
    fixed_charge: 'Fixed monthly charges',
    energy_charge: 'Energy Charges',
    telescopic: 'Telescopic',
    non_telescopic: 'Non-Telescopic',
    unit: 'Units',
    rate: 'rate',
    show_my_system: 'Show My System',
    uk_roof_top_tooltip:
      'The total roof top area is derived basis single panel size of 3.3ft x 5.4ft.',
    ind_roof_top_tooltip:
      'The total roof top area is derived basis single panel size of 2.08m x 1m.',
    tariff_info:
      '*Tariff rates are based on averages and subject to change basis your specific location.',
    savings_over: 'Savings over 25 years',
    savings_over_uk: 'Savings per year',
    is_seg:
      'These are estimated savings to you over 25 years - Enphase systems last long, and once your system pays back for itself each month, you will save on electricity bills you would otherwise have to pay. `&#13;&#13;` Under the Smart Export Guarantee (SEG) scheme you get paid for surplus electricity fed into the grid. Please note you need to sign up for SEG by fulfilling criteria set by the company provider, who decides rate for the electricity fed, length of contract and other terms. For calculation purpose rate of 4.6p/kW is considered here.',
    not_seg:
      'These are estimated savings to you over 25 years. Without Smart Export Guarantee & storage the surplus energy remains unused. With increase in system size, system cost goes up resulting in reduction in savings without SEG.`&#13;&#13;` Please note your savings are optimum when chosen system size is equal to recommended system size',
    including_seg: 'With SEG',
    excluding_seg: 'Without SEG',
    ind_savings_tooltip:
      'These are the estimated savings to you over 25 years - Enphase systems last long, and once your system pays back for itself, each month you will save on electricity bills you would otherwise have to pay.',
    new_electricity_bill: 'New Electricity Bill',
    new_electricity_bill_uk: 'New average electricity bill',
    ebill_tooltip:
      'This amount is only illustrative basis the data provided by you without considering the effect of SEG rate. Your actual bill amount will depend on multiple factors such as sanctioned load, environmental conditions, your electricity consumption pattern & SEG tariff.',
    payback_period: 'Payback Period',
    year: ' years',
    payback_period_tooltip:
      'The amount of time your system will pay back for itself i.e. the time it will take for you to recover your investment.',
    total_cost: 'Total Cost',
    total_cost_uk: 'Total system cost',
    total_cost_tooltip:
      'This is an estimated cost for your full system including installation, commissioning and other charges so that you can start using your system right away.',
    total_cost_tooltip_ind:
      'This is an estimated cost for your full system including installation and commissioning charges.',
    interested: 'Interested ?',
    contact_enphase: 'Interested? Connect with us',
    download_tooltip:
      'Please fill the Contact Enphase form to download the report.',
    download: 'Download',
    safety_first: 'Safety First',
    smart_power: 'Smart Power',
    super_savings: 'Super Savings',
    note: 'Note: All calculations are estimates only and often rely on data provided by third party sources. You are solely responsible for ensuring that the data you enter into the System Estimator is correct and for verifying the accuracy of the data generated by the System Estimator.',
    EDIT: 'Edit info',
    update_info: 'Update Information',
    postcode: 'Postcode',

    avg_monthly_bill: 'Average Monthly Bill',
    avg_monthly_bill_uk: 'Average bill amount',

    energy_consumption: 'Energy consumption',
    done: 'DONE',
    Edit: 'Edit',
    Close: 'Close',
    select: 'Select',
    modal_header: 'Connect with our experts by sharing your contact details.',
    full_name: 'Your full name',
    phone_no: 'Your phone number',
    Email: 'Your email',
    Captcha: 'Captcha',
    policy_1: 'I consent to Enphase’s',
    policy_2:
      ' and agree to be contacted by Enphase or its network of installers',
    privacy_policy: 'Privacy Policy',
    terms_of_service: 'Terms of Service',
    and: ' and ',
    submit: 'SUBMIT',
    policy_3:
      ' and agree to be contacted by Enphase or its network of installers.',
    info: `I agree that Enphase and/or its network of installers may contact me via  calls and text messages at the phone number entered here. These marketing
      calls and texts may be auto-dialed or generated by an artificial or
      pre-recorded voice. My consent to these communications is not a condition
      of any purchase and may be revoked at any time and by any reasonable means.`,
    modal_note:
      'Note: System size and quote to be finalised by your Enphase Installer.​',
    size_large: 'Required solar system size is very large.​ ',
    block_para:
      'Estimated values only. Final quote will be provided by the installer.',
    storage_ready: 'Storage Ready',
    uk_note:
      'Note: If your bill contains both Gas & Electricity charges, please enter only the electricity charge amount.',
    uk_form_agree:
      'I would like to be notified about Enphase storage solutions in future.',
  };
  public titles = {
    title_uk: 'Enphase Solar System Estimator',
    title_in: 'Enphase India Solar System Estimator',
    title_br: 'Enphase Brazil Solar System Estimator'
  };
  public links = {
    privacy_policy: 'https://www4.enphase.com/en-us/legal/privacy-policy',
    terms_of_service: 'https://www4.enphase.com/en-us/legal/terms-of-service',
    enphase_ind: 'https://www.enphase.com/en-in',
    enphase_uk: 'https://www.enphase.com/en-uk',
  };
}
