import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  AfterContentInit,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { LoaderService } from '../app/shared/loader.service';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';

import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { NotificationsService } from './shared/notifications.service';
import { Constant } from './shared/constant';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
declare const feedbackify: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('flyIn', [
      state('in', style({ transform: 'translateY(0)', opacity: 1 })),
      transition('void => *', [
        style({ transform: 'translateY(-100%)', opacity: 0 }),
        animate('0.5s ease-out'),
      ]),
    ]),
    trigger('flyInFooter', [
      state('in', style({ transform: 'translateY(0)', opacity: 1 })),
      transition('void => *', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('0.5s ease-out'),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit {
  loaded = false;
  showLoader = false;
  country: any;
  newUrl: any[] = [];
  url: any;
  urlhttpClient: HttpClient;
  constants: Constant;
  isGATagAvailable: boolean = false;

  @HostListener('window:onbeforeunload', ['$event'])
  clearLocalStorage(): void {
    localStorage.clear();
    sessionStorage.clear();
  }
  constructor(
    private handler: HttpBackend,
    private lService: LoaderService,
    private constant: Constant,
    private router: Router,
    private titleService: Title
  ) {
    if (window.location.href.toString().includes('_ga')) {
      this.isGATagAvailable = true;
    } else if (window.location.href.toString()?.split('?').length > 1) {


      let urlExtractData = window.location.href.toString()?.split('?')[1]
      let data = urlExtractData.split('&');      
      sessionStorage.clear();
      data.map((test) => {
        var value = test.split('=')
        sessionStorage.setItem(value[0], value[1])
      }
      )
    }
     else {
      sessionStorage.setItem('utm_source', '');
      sessionStorage.setItem('utm_medium', '');
      sessionStorage.setItem('utm_campaign', '');
      sessionStorage.setItem('utm_content', '');
      sessionStorage.setItem('utm_id', '');
    }
    this.constants = this.constant;
    this.urlhttpClient = new HttpClient(this.handler);
    // this.getCurrentLocation();
  }


  ngOnInit(): void {
    
  }
  feedbackify() {
    var f = document.createElement("script");
    f.type = "text/javascript";
    f.async = true;
    f.src = "//cdn.feedbackify.com/f.js";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode?.insertBefore(f, s);
  }
  getCurrentLocation(): void {
    const nurl = environment.locationUrl;
    this.lService.showLoader();
    this.urlhttpClient
      .get(nurl, {
        headers: new HttpHeaders().set(
          'Content-Type',
          'application/x-www-form-urlencoded'
        ),
        responseType: 'json',
      })
      .subscribe(
        (res: any) => {
          this.lService.hideLoader();
          this.country = res.countryCode;

          this.country === this.constants.labels.UK
            ? this.titleService.setTitle(this.constants.titles.title_uk)
            : this.country === this.constants.labels.IN
              ? this.titleService.setTitle(this.constants.titles.title_in)
              : this.country === this.constants.labels.NL
                ? this.titleService.setTitle(this.constants.titles.title_uk)
                : this.country === this.constants.labels.BR
                  ? this.titleService.setTitle(this.constants.titles.title_br)
                  : this.titleService.setTitle('');

          localStorage.setItem(
            'country',
            this.country === this.constants.labels.UK
              ? this.constants.labels.UK
              : this.country === this.constants.labels.BR
                ? this.constants.labels.BR
                : this.country
          );

          if (
            this.country === this.constants.labels.UK ||
            this.country === this.constants.labels.IN ||
            this.country === this.constants.labels.NL ||
            this.country === this.constants.labels.BR
          ) {
            let navigationURL = this.country === this.constants.labels.UK
              ? this.constants.labels.UK
              : this.country === this.constants.labels.NL
                ? this.constants.labels.UK
                : this.country == this.constants.labels.BR
                  ? this.constants.labels.BR
                  : this.constants.labels.UK;

            const utmParams: Object = this.getUTMObject();

            if (this.isGATagAvailable) {

              this.router.navigate([
                '',
                navigationURL
              ],
                { queryParamsHandling: 'preserve' });
            } else {
              if (navigationURL == "IN")
                this.feedbackify();

              this.router.navigate([
                '',
                navigationURL
              ], { queryParams: utmParams });
            }
          } else {
            this.router.navigate(['/unavailable']);
          }
        },
        (err) => {
          // this.notificationService.error(err);
        }
      );

    this.country = localStorage.getItem('country');
  }

  getUTMObject(): Object {
    const utmSource = sessionStorage.getItem('utm_source') || '';
    const utmMedium = sessionStorage.getItem('utm_medium') || '';
    const utmContent = sessionStorage.getItem('utm_content') || '';
    const utmCampaign = sessionStorage.getItem('utm_campaign') || '';
    const utmId = sessionStorage.getItem('utm_id') || '';
    const utmTerm = sessionStorage.getItem('utm_term') || '';
    
    const utmParams: any = {};
    if(utmSource)
      utmParams.utm_source = utmSource;
    if(utmMedium)
      utmParams.utm_medium = utmMedium;
    if(utmCampaign)
      utmParams.utm_campaign = utmCampaign;
    if(utmContent)
      utmParams.utm_content = utmContent;
    if(utmId)
      utmParams.utm_id = utmId;
    if(utmTerm)
      utmParams.utm_term = utmTerm;
    return utmParams;
  }

  openFeedback(): void {
    (window as any).fby.push(['showForm', 15904]);
  }

  ngAfterContentInit(): void {
    this.lService.isLoading.subscribe((value: boolean) => {
      this.showLoader = value;
    });
  }

  ngOnDestroy(): void {
    this.clearLocalStorage();
  }
}
