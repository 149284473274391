// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  locationUrl:
    'https://api-prod.secureprivacy.ai/api/adminsettings/displaypage/ipinfo',
  apiBaseUrl: 'https://enphase-system-estimator-api.scketch.com',
  ukApiBaseUrl:'https://enphase-uk-calculator-api.scketch.com',
  enphase_link_IND: 'https://enphase-india-calculator.scketch.com/',
  enphase_link_UK: 'https://enphase-uk-calculator-api.scketch.com/',
  lead_conduit_IND:
  'https://app.leadconduit.com/flows/5e69c1fe99a97d13c1afeb8e/sources/60924a69ef9fc91d18058ff5/submit',
  // 'https://app.leadconduit.com/flows/60dd5d359bcd5b29014e7da1/sources/611ce9ac805db1ab18320d1d/submit',
  lead_conduit_UK:
    'https://app.leadconduit.com/flows/60dd5d359bcd5b29014e7da1/sources/618b9ccdc1cf8c1845088f60/submit',
  //'https://app.leadconduit.com/flows/60dd5d359bcd5b29014e7da1/sources/611ce9ac805db1ab18320d1d/submit',
  lead_conduit_BR:'https://app.leadconduit.com/flows/62161249f3fd4f7634205d4b/sources/6216212e5d331c48cd446259/submit',

  googleAnalyticsKey: 'AW-322950818',
  leadconduitURL:
  'https://app.leadconduit.com/flows/60dd5d359bcd5b29014e7da1/sources/618b9ccdc1cf8c1845088f60/submit',
  // lead_conduit_IND:
  //   'https://app.leadconduit.com/flows/60dd5d359bcd5b29014e7da1/sources/60ec472ac0e7017686be2664/submit',
  // lead_conduit_UK:
  //   'https://app.leadconduit.com/flows/60dd5d359bcd5b29014e7da1/sources/60ec4760bd09b4e2a793d503/submit',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
