import { Component, OnInit } from '@angular/core';
import { MatDialogRef,MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoaderService } from '../shared/loader.service';

@Component({
  selector: 'app-country-selection',
  templateUrl: './country-selection.component.html',
  styleUrls: ['./country-selection.component.scss']
})
export class CountrySelectionComponent implements OnInit {
  
  country:any ;
  constructor(   
     public dialog: MatDialog,
    private router : Router,
    public dialogRef: MatDialogRef<CountrySelectionComponent>,
    private loadService: LoaderService,

     ) { }

  ngOnInit(): void {
   this.country = localStorage.getItem('country')==="IN"? "GB": localStorage.getItem('country')
  }
  openCountry(country: string){
    localStorage.setItem('country',country)
    this.loadService.showLoader();
    this.router.navigate([
      '',
      country
    ]);
    this.loadService.hideLoader();
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }
}
