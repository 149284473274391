import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RecaptchaModule } from 'angular-google-recaptcha';

import { AppComponent } from './app.component';
import { HttpInterceptorService } from './shared/http-interceptor.service';
import { CurrencyPipe } from '@angular/common';
import { IndiancurrencyPipe } from './shared/indiancurrency.pipe';
import { Constant } from './shared/constant';
import { NotAvailableComponent } from './not-available/not-available.component';
import { CountrySelectionComponent } from './country-selection/country-selection.component';
// import { DetailsModalComponent } from './details-modal/details-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    IndiancurrencyPipe,
    NotAvailableComponent,
    CountrySelectionComponent,
    // DetailsModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RecaptchaModule.forRoot({
      siteKey: '6LefHh0bAAAAANT_s_KyMndATuf-qNhvn0X2k52W',
    }),
    FormsModule,
    MatSnackBarModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [
    Constant,
    CurrencyPipe,
    IndiancurrencyPipe,
    
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    { provide: 'googleTagManagerId', useValue: 'GTM-WDJV9F' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
