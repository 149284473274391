<button class="close-btn" mat-dialog-close (click)="close()">
  <img src="/assets/images/IN/close_icon.svg" width="14" height="14" />
</button>
<div class="modal-container">
  <p class="m-title">
    Choose your country.
  </p>
  <div class="country-link">
    <a (click)="openCountry('GB')" [ngClass]="{'active-country': country === 'GB'}">
      <img src="/assets/images/UK/UK_Flag.png" width="20px" height="20px">
      <span>UK</span>
    </a>
    <a (click)="openCountry('BR')" [ngClass]="{'active-country': country === 'BR'}">
      <img src="/assets/images/BR/brazil.png" width="20px" height="20px">
      <span>BR</span>
    </a>
  </div>



</div>